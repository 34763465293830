<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Brand</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm autocomplete="off" v-else>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <div class="mb-3 text-center">
                    <input
                      class="d-none"
                      type="file"
                      @change="selectFile"
                      accept="image/*"
                      ref="image"
                    />
                    <img
                      @click="$refs.image.click()"
                      height="150px"
                      :src="imageUrl"
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Brand Name"
                    v-model="brand_to_edit.name"
                    placeholder="Enter brand name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="brand_to_edit.name ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Product Group</label
                      >
                    </div>
                    <select
                      required
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="brand_to_edit.product_group.id"
                      @keyup="check_product_group_id"
                      :addInputClasses="
                        product_group_id_invalid ? 'is-invalid' : null
                      "
                      :isValid="brand_to_edit.product_group.id ? true : null"
                    >
                      <template v-for="product_group in product_groups">
                        <option
                          :key="product_group.id"
                          :value="product_group.id"
                        >
                          {{ product_group.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Description"
                    v-model="brand_to_edit.description"
                    placeholder="Enter Description..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
 import {api} from '@/api' 
export default {
  name: "BrandEdit",
  data() {
    return {
      brand_to_edit: [],
      name_invalid: false,
      image: null,
      changedImage: null,
      product_group_id_invalid: false,
    };
  },
  computed: {
    ...mapState({
      product_groups: (state) => state.product_groups.product_groups,
      editBrand: (state) => state.brands.editBrand,
      isSaving: (state) => state.brands.isSaving,
      isLoading: (state) => state.brands.isLoading,
    }),
    imageUrl() {
      return this.changedImage
        ? this.changedImage
        : this.brand_to_edit.image ||
            `${api.url}/core/public/uploads/no-photo.png`;
    },
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editBrand) {
      this.$router.push({ name: "Brands" });
    }
    this.$store.commit("brands/loading", true);
    this.$store.dispatch("product_groups/getAllProductGroups");
    this.beforeEditingCache = JSON.stringify(this.editBrand);
    this.brand_to_edit = this.editBrand
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
  },

  methods: {
    selectFile(event) {
      this.image = event.target.files[0];
      this.changedImage = URL.createObjectURL(this.image);
    },
    check_name() {
      if (!this.brand_to_edit.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },
    check_product_group_id() {
      if (!this.brand_to_edit.product_group.id) {
        this.product_group_id_invalid = true;
      } else {
        this.product_group_id_invalid = false;
      }
    },
    checkForm() {
      if (!this.brand_to_edit.name) {
        this.check_name();
      } else {
        return true;
      }
      if (!this.brand_to_edit.product_group.id) {
        this.check_product_group_id();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        const data = new FormData();
        data.append("id", this.brand_to_edit.id);
        data.append("img", this.image);
        data.append("name", this.brand_to_edit.name);
        data.append("description", this.brand_to_edit.description);
        data.append("product_group_id", this.brand_to_edit.product_group.id);
        this.$store.dispatch("brands/updateBrand", data);
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 